import React from "react";
import background from "./backgroundblur.png";
import './Home.css';
import stravaConnectImage from "./btn_strava_connectwith_orange.svg"

const { REACT_APP_CLIENT_ID } = process.env;
const redirectUrl = "https://strava.chanelmuir.com/redirect"

const handleLogin = () => {
    window.location = `http://www.strava.com/oauth/authorize?client_id=${REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${redirectUrl}/exchange_token&approval_prompt=force&scope=activity:read`;
};

const Home = () => {
    return (
        <div style={{fontFamily: 'Roboto', backgroundImage:`url(${background})`, backgroundPosition: "center", backgroundSize: "cover", height: "100vh" }}>
            <h1 class="center" id="title"><span>Where haven't I run?</span></h1>
            <p class="center"><span>Sign in with Strava to view all your activities on one map.</span></p>
            
            <div class="centerAll">
                <button id="stravaButton"><img src={stravaConnectImage} alt="Connect with Strava" onClick={handleLogin}/></button>
            </div>
            <div class="createdByChanel">
                <p><span>Created by Chanel Muir</span></p>
            </div>

        </div>
    );
};

export default Home;