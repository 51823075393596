import React from "react";
import { connect } from "react-redux";
import { MapContainer, TileLayer, Popup, Polyline } from 'react-leaflet'
import './YourDistance.css';

const YourDistance = ({ user, returnTokens }) => {
    return (
        
        <div>
            
            <MapContainer center={[-43.5308977, 172.6366200]} zoom={15} scrollWheelZoom={true}>
                <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY2hpY2tlbm1hbiIsImEiOiJjanU2cWI2dHMwbGR3M3lrOTBvMHU1YzFkIn0.YEu0MESiHoepEspYsbF_HA"
                />
                {user.map((each, i) => (
                    
                    <Polyline color="#fc5200" key = {i} positions = {each.activityPositions}> 
                        <Popup>
                            <div>
                                <h3>{each.activityName}</h3>
                                <p>{each.activityDistance}km</p>
                                <p>{each.activityTime}min</p>
                                <p><a href={each.activityId} target="_blank" rel="noreferrer">View on Strava</a></p>
                            </div>
                        </Popup>
                    </Polyline>
                ))}
                
            
                

            </MapContainer>
            
        </div> 
        
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        returnTokens: state.returnTokens,
    };
};

export default connect(mapStateToProps)(YourDistance);
