import axios from "axios";
import polyline from '@mapbox/polyline';


const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET } = process.env;


export const getParamValues = (url) => {
    return url
        .slice(1)
        .split("&")
        .reduce((prev, curr) => {
            const [title, value] = curr.split("=");
            prev[title] = value;
            return prev;
        }, {});
};

export const cleanUpAuthToken = (str) => {
    return str.split("&")[1].slice(5);
};

export const testAuthGetter = async (authTok) => {
    try {
        const response = await axios.post(
            `https://www.strava.com/api/v3/oauth/token?client_id=${REACT_APP_CLIENT_ID}&client_secret=${REACT_APP_CLIENT_SECRET}&code=${authTok}&grant_type=authorization_code`
        );
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getUserData = async (userID, accessToken) => {
    try {

        let response = [];
        let prevResponseLength = 0;
        let pages = 1;
        do {
            prevResponseLength = response.length;
            response.push(await axios.get(
                `https://www.strava.com/api/v3/activities?page=${pages}&per_page=200&access_token=${accessToken}`,
                { headers: { Authorization: `Bearer ${accessToken}` } }
                ));

                pages++;
                console.log(pages);

                if (pages > 8){
                    break;
                }
                

            
        } while (response.length > prevResponseLength)
        console.log(response);
        console.log(response[0].data[0].map.summary_polyline);

        const polylines = [];
        for (let q = 0; q < pages - 1; q++)
        {
            console.log(response[q].data.length);
            for (let w = 0; w < response[q].data.length; w++) {

                const activity_polyline = response[q].data[w].map.summary_polyline;
                const activity_name = response[q].data[w].name;
                const activity_distance = Number.parseFloat(response[q].data[w].distance / 1000).toPrecision(4);
                const activity_time = Number.parseFloat(response[q].data[w].moving_time / 60).toPrecision(3);
                const activity_id = response[q].data[w].map.id.replace('a', "https://www.strava.com/activities/");
                if (activity_polyline != null){
                    polylines.push({activityPositions: polyline.decode(activity_polyline), activityName: activity_name, activityDistance: activity_distance, activityTime: activity_time, activityId: activity_id});
                }
                
            }
        }
        

        return polylines;
    } catch (error) {
        console.log(error);
    }
};
