export const setUserActivities = (data) => {
    return {
        type: "SET_USER_ACTIVITIES",
        payload: data,
    };
};

export const setUser = (data) => {
    return {
        type: "SET_USER",
        payload: data,
    };
};

